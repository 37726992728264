// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-coc-js": () => import("/home/kitconcept/workspace/kitconcept_react-barcamp.de_main/src/pages/coc.js" /* webpackChunkName: "component---src-pages-coc-js" */),
  "component---src-pages-imprint-js": () => import("/home/kitconcept/workspace/kitconcept_react-barcamp.de_main/src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("/home/kitconcept/workspace/kitconcept_react-barcamp.de_main/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/kitconcept/workspace/kitconcept_react-barcamp.de_main/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

exports.data = () => import("/home/kitconcept/workspace/kitconcept_react-barcamp.de_main/.cache/data.json")

